import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import type { RegistrationFormState } from '@bladebinge/types';
import { validateEmail } from '@bladebinge/web-service-common/src/validations/validate-email';
import {
    validatePassword,
    validatePasswordMatch
} from '@bladebinge/web-service-common/src/validations/validate-password';
import { validateUsername } from '@bladebinge/web-service-common/src/validations/validate-username';
import { DB_STRING_MAX } from '@bladebinge/web-service-common/src/constants/database-constants';
import { useTranslation } from 'next-i18next';
import { nextLocalStorage } from '../../utils/next-local-storage-helper';
import { usePurchaseInfoContext } from '../../context/purchase-info-context';
import { useStateSafe } from '../../hooks/use-state-safe';
import { registerAccount } from '../../server/api-proxy/account';
import { LoadingIndicator } from '../atoms/LoadingIndicator';
import { TermsOfServiceText } from '../atoms/TermsOfServiceText';
import {
    StyledFormLegalContentWrap,
    StyledFormMessagingWrapper,
    StyledFullWidthForm,
    StyledHookFormErrorMessage,
    StyledModalCancelIcon
} from '../styled-shared';
import { useReferralCodeContext } from '../../context/referral-code/referral-code-context';
import { FormHeader } from './FormHeader';

interface FormProps {
    readonly loginToggle: (userRedirectId?: string) => void;
    readonly onSuccessfulRegistration?: (success: boolean) => void;
    readonly redirect?: string;
    readonly showFormHeader?: boolean;
    readonly successButtonText?: string;
    readonly successCallback?: (formData: RegistrationFormState) => void;
}

export const RegisterForm = ({ loginToggle, onSuccessfulRegistration, showFormHeader = true }: FormProps) => {
    const { t } = useTranslation();
    const { setNewRegistrationBuyerId } = usePurchaseInfoContext();
    const { referredBy } = useReferralCodeContext();

    const [registrationState, setRegistrationState] = useStateSafe<{
        error: string;
        processingRegistration: boolean;
        success: boolean;
        userAgreedToTerms: boolean;
    }>({
        error: '',
        processingRegistration: false,
        success: false,
        userAgreedToTerms: false
    });
    const [userRedirectId, setUserRedirectId] = useStateSafe<string>('');
    const { processingRegistration, success, error: registrationError, userAgreedToTerms } = registrationState;

    const [localStorageState, setLocalStorageState] = useState<{
        email: string;
        rememberMe: boolean;
    }>({
        rememberMe: false,
        email: ''
    });

    const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);

    const {
        formState: { errors },
        getValues,
        handleSubmit,
        register,
        setValue
    } = useForm<RegistrationFormState>({
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: true
    });

    // on mount update from local storage
    useEffect(() => {
        const localRememberMe = nextLocalStorage.getAsBoolean('rememberMe');
        const rememberedEmail = localRememberMe ? (nextLocalStorage.get('email') ?? '') : '';
        setLocalStorageState({
            email: rememberedEmail,
            rememberMe: localRememberMe
        });
    }, []);

    const toggleTermsModalOpen = () => {
        setIsTermsModalOpen(!isTermsModalOpen);
    };

    const agreeAndClose = () => {
        setRegistrationState((prevState) => ({
            ...prevState,
            userAgreedToTerms: true
        }));
        setIsTermsModalOpen(false);
    };

    const handleRegister = async (formData: RegistrationFormState) => {
        setRegistrationState((prevState) => ({
            ...prevState,
            processingRegistration: true
        }));

        const response = await registerAccount({
            ...formData,
            ...(referredBy ? { referredBy } : {})
        });
        const { error: { message: errorMessage = '' } = {}, message, redirectId = '' } = response;
        setUserRedirectId(redirectId);
        setNewRegistrationBuyerId(redirectId);

        // save local storage state
        const { email, rememberMe } = formData;
        rememberMe ? nextLocalStorage.set('rememberMe', rememberMe.toString()) : nextLocalStorage.remove('rememberMe');
        email ? nextLocalStorage.set('email', email) : nextLocalStorage.remove('email');

        const success = Boolean(message);

        setRegistrationState((prevState) => ({
            ...prevState,
            error: errorMessage,
            processingRegistration: false,
            success
        }));

        if (onSuccessfulRegistration) {
            onSuccessfulRegistration(success);
        }
    };

    const handleRememberCheck = (_e: React.ChangeEvent<unknown>, checked: boolean) => {
        const useridValue = getValues('username');
        const emailValue = getValues('email');
        const userData = {
            ...(emailValue ? { email: emailValue } : {}),
            ...(useridValue ? { userid: useridValue } : {})
        };

        setLocalStorageState((prevState) => ({
            ...prevState,
            ...(checked ? userData : {}),
            rememberMe: checked
        }));
    };

    const handleTermsCheck = (_e: React.ChangeEvent<unknown>, checked: boolean) => {
        setRegistrationState((prevState) => ({
            ...prevState,
            userAgreedToTerms: checked
        }));
        setValue('agreeToTerms', checked);
    };

    // hook form material UI register calls
    const { ref: emailRef, ...emailRest } = register('email', {
        validate() {
            const email = getValues('email');
            const isValid = validateEmail(email);
            return isValid;
        }
    });
    const { ref: passwordRef, ...passwordRest } = register('password', {
        required: true,
        validate() {
            return validatePassword(getValues('password'));
        }
    });
    const { ref: firstNameRef, ...firstNameRest } = register('firstName', { maxLength: DB_STRING_MAX, required: true });
    const { ref: lastNameRef, ...lastNameRest } = register('lastName', { maxLength: DB_STRING_MAX, required: true });
    const { ref: usernameRef, ...usernameRest } = register('username', {
        maxLength: DB_STRING_MAX,
        required: true,
        validate() {
            const username = getValues('username');
            return validateUsername(username);
        }
    });
    const { ref: confirmedPasswordRef, ...confirmedPasswordRest } = register('confirmedPassword', {
        required: true,
        validate() {
            return validatePasswordMatch(getValues('password'), getValues('confirmedPassword'));
        }
    });
    const { ref: agreeToTermsRef, ...agreeToTermsRest } = register('agreeToTerms', {
        validate: () => getValues('agreeToTerms') === true
    });
    const { ref: rememberMeRef, ...rememberMeRest } = register('rememberMe');

    return (
        <>
            {showFormHeader && <FormHeader icon="account" title={t('common:auth.create_account')} />}
            <StyledFullWidthForm onSubmit={handleSubmit(handleRegister)}>
                {registrationError && (
                    <StyledFormMessagingWrapper>
                        <Alert
                            data-testid="registration_error"
                            onClose={() => {
                                setRegistrationState((prevState) => ({
                                    ...prevState,
                                    error: ''
                                }));
                            }}
                            severity="error"
                        >
                            {registrationError}
                        </Alert>
                    </StyledFormMessagingWrapper>
                )}
                {success && (
                    <StyledFormMessagingWrapper>
                        <Grid container>
                            <Grid item>
                                <Alert data-testid="registration_success_msg" severity="success">
                                    <Typography>{t('common:user.thank_you_for_registering')}</Typography>
                                    <Typography>{t('common:user.registration_success_created')}</Typography>
                                </Alert>
                                <Alert data-testid="verification_warning_msg" severity="warning" sx={{ mt: 1 }}>
                                    <Typography variant="h5" component="p" sx={{ position: 'relative', bottom: '8px' }}>
                                        {t('common:user.registration_email_verification_required')}
                                    </Typography>
                                    <Typography>{t('common:user.registration_success_check_email')}</Typography>
                                    <Typography>{t('common:user.registration_success_click_link')}</Typography>
                                </Alert>
                            </Grid>
                        </Grid>
                    </StyledFormMessagingWrapper>
                )}
                {!success && (
                    <>
                        <Grid container>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                autoComplete={t('common:user.email')}
                                className={errors.email ? 'input-error' : ''}
                                fullWidth
                                id="email"
                                inputRef={emailRef}
                                label={t('common:user.email')}
                                placeholder={t('common:user.email')}
                                margin="normal"
                                required
                                variant="outlined"
                                {...emailRest}
                            />
                            <StyledHookFormErrorMessage
                                as="div"
                                errors={errors}
                                message={t('common:auth.errors.invalid_email')}
                                name="email"
                            />
                        </Grid>
                        <Grid container>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                autoComplete={t('common:auth.autocomplete_labels.nickname')}
                                className={errors.username ? 'input-error' : ''}
                                fullWidth
                                id="username"
                                inputRef={usernameRef}
                                label={t('common:user_profile_form.username')}
                                placeholder={t('common:user_profile_form.username')}
                                margin="normal"
                                required
                                variant="outlined"
                                {...usernameRest}
                            />
                            <StyledHookFormErrorMessage
                                as="div"
                                errors={errors}
                                message={t('common:user_profile_form.errors.username_error')}
                                name="username"
                            />
                        </Grid>
                        <Grid container>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                autoComplete={t('common:user.first_name')}
                                className={errors.firstName ? 'input-error' : ''}
                                fullWidth
                                id="firstName"
                                inputRef={firstNameRef}
                                label={t('common:user.first_name')}
                                placeholder={t('common:user.first_name')}
                                margin="normal"
                                required
                                variant="outlined"
                                {...firstNameRest}
                            />
                            <StyledHookFormErrorMessage
                                as="div"
                                errors={errors}
                                message={t('common:auth.errors.invalid_first_name')}
                                name="firstName"
                            />
                        </Grid>
                        <Grid container>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                autoComplete={t('common:user.last_name')}
                                className={errors.lastName ? 'input-error' : ''}
                                fullWidth
                                id="lastName"
                                inputRef={lastNameRef}
                                label={t('common:user.last_name')}
                                placeholder={t('common:user.last_name')}
                                margin="normal"
                                required
                                variant="outlined"
                                {...lastNameRest}
                            />
                            <StyledHookFormErrorMessage
                                as="div"
                                errors={errors}
                                message={t('common:auth.errors.invalid_last_name')}
                                name="lastName"
                            />
                        </Grid>
                        <Grid container>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                autoComplete="new-password"
                                className={errors.password ? 'input-error' : ''}
                                fullWidth
                                id="password"
                                inputRef={passwordRef}
                                label={t('common:auth.password')}
                                margin="normal"
                                placeholder={t('common:auth.password')}
                                required
                                type="password"
                                variant="outlined"
                                {...passwordRest}
                            />
                            <StyledHookFormErrorMessage
                                as="div"
                                data-testid="registration_password_error"
                                errors={errors}
                                message={t('common:auth.errors.invalid_password')}
                                name="password"
                            />
                        </Grid>
                        <Grid container>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                className={errors.confirmedPassword ? 'input-error' : ''}
                                id="confirmedPassword"
                                inputRef={confirmedPasswordRef}
                                label={t('common:auth.confirm_password')}
                                margin="normal"
                                placeholder={t('common:auth.confirm_password')}
                                required
                                type="password"
                                variant="outlined"
                                {...confirmedPasswordRest}
                            />
                            <StyledHookFormErrorMessage
                                as="div"
                                data-testid="registration_confirm_password_error"
                                errors={errors}
                                message={t('common:auth.errors.invalid_confirmed_password')}
                                name="confirmedPassword"
                            />
                        </Grid>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <FormControlLabel
                                    checked={userAgreedToTerms}
                                    control={<Checkbox />}
                                    ref={agreeToTermsRef}
                                    label={t('common:auth.agree_to_terms')}
                                    {...agreeToTermsRest}
                                    onChange={handleTermsCheck}
                                />
                                <Button
                                    color="primary"
                                    data-testid="terms_modal_btn"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        toggleTermsModalOpen();
                                    }}
                                    sx={{
                                        ml: 1,
                                        textDecoration: 'underline',
                                        '&:hover': {
                                            textDecoration: 'underline'
                                        }
                                    }}
                                    size="large"
                                >
                                    {t('common:navigation.terms_of_service')}
                                </Button>
                                <StyledHookFormErrorMessage
                                    as="div"
                                    data-testid="terms_error_msg"
                                    errors={errors}
                                    message={t('common:auth.errors.you_must_agree_to_terms_of_service')}
                                    name="agreeToTerms"
                                />
                                <Modal
                                    aria-label={t('common:navigation.terms_of_service')}
                                    open={isTermsModalOpen}
                                    onClose={toggleTermsModalOpen}
                                    closeAfterTransition
                                    components={{
                                        Backdrop
                                    }}
                                    sx={{ overflow: 'scroll' }}
                                >
                                    <StyledFormLegalContentWrap>
                                        <StyledModalCancelIcon onClick={toggleTermsModalOpen} />
                                        <TermsOfServiceText />
                                        <Grid container justifyContent="flex-end">
                                            <Grid item>
                                                <Button color="primary" onClick={agreeAndClose} variant="contained">
                                                    {t('common:auth.i_agree')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </StyledFormLegalContentWrap>
                                </Modal>
                            </Grid>
                        </Grid>
                        <Button
                            color="primary"
                            data-testid="create_account_btn"
                            disabled={processingRegistration}
                            fullWidth
                            size="large"
                            sx={{
                                mt: 2,
                                mx: 0,
                                mb: 3
                            }}
                            type="submit"
                            variant="contained"
                        >
                            {processingRegistration ? (
                                <LoadingIndicator color="primary" inline />
                            ) : (
                                t('common:auth.create_account')
                            )}
                        </Button>
                        <Grid
                            container
                            justifyContent="space-between"
                            sx={{
                                flexDirection: {
                                    xs: 'row-reverse',
                                    sm: 'inherit'
                                },
                                textAlign: {
                                    xs: 'left',
                                    sm: 'center'
                                }
                            }}
                        >
                            <Grid item>
                                <Button color="primary" onClick={() => loginToggle(userRedirectId)}>
                                    {t('common:user.have_account_login')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    checked={localStorageState.rememberMe}
                                    control={<Checkbox color="info" />}
                                    inputRef={rememberMeRef}
                                    label={t('common:auth.remember_me')}
                                    {...rememberMeRest}
                                    onChange={handleRememberCheck}
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </StyledFullWidthForm>
        </>
    );
};
